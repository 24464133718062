/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { 
    margin: 0; 
    font-family: Roboto, "Helvetica Neue", sans-serif; 
}


//this overlay create a backgroun blur when you open any modal
.cdk-overlay-backdrop {
    background-color: rgba(247, 235, 235, 0.5); /* Semi-transparent black */
    backdrop-filter: blur(10px); /* Apply the blur effect */
    -webkit-backdrop-filter: blur(10px); /* Safari support */
}


// ======================================================================================
// by default when you open ngx-material-timepicker, it opens at the back of the modal 
// the styles below makes ngx-material-timepicker come to the top when opened from a modal 

.timepicker-overlay {
    z-index: 10000000 !important;
}

.timepicker-backdrop-overlay {
    z-index: 10000000 !important;
}

// ======================================================================================